<template>
  <a-layout>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>{{ $route.name }}</a-breadcrumb-item>
    </a-breadcrumb>

    <a-layout-content class="content">
      <a-page-header style=" padding: 0;" title="Account" />

      <a-descriptions style="margin-top:20px" title="" bordered :column="1">
        <a-descriptions-item label="Name">
          <a-row type="flex" justify="space-between">
            <a-col :span="6">
              <a-typography-text strong>
                {{ data.name }}
              </a-typography-text>
            </a-col>
            <a-col :xl="6">
            </a-col>
          </a-row>
        </a-descriptions-item>
        <a-descriptions-item label="Username">
          <!-- username -->
          <a-row type="flex" justify="space-between">
            <a-col :span="6">
              <a-typography-text strong>
                {{ data.username }}
              </a-typography-text>
            </a-col>
            <a-col :xl="6">
            </a-col>
          </a-row>
        </a-descriptions-item>
        <a-descriptions-item label="Email">
          <!-- email -->
          <a-row type="flex" justify="space-between">
            <a-col :span="6">
              <a-typography-text strong> {{ data.email }} </a-typography-text>
            </a-col>
            <a-col :xl="6">
            </a-col>
          </a-row>
        </a-descriptions-item>
      </a-descriptions>
    </a-layout-content>

    <a-layout-footer class="footer">&copy;2021 Springup Inc.</a-layout-footer>
  </a-layout>
</template>

<script>
import {
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  getCurrentInstance,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Modal } from "ant-design-vue";
export default {
  components: {},
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const v = reactive({
      data: store.state.userInfo,
      loading: false,
      tobeUpdate: {},
      shownameModal: false,
      newname: "",
      activeKey: "name",

      showpwdModal: false,
      oldpwd: "",
      newpwd1: "",
      newpwd2: "",

      finished: false,
    });

    async function updateUser() {
      let obj = {};
      if (v.activeKey == "email") {
        var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if (!reg.test(v.tobeUpdate.email)) {
          proxy.$msg.warning("Please enter a valid email");
          return;
        }
      }
      obj[v.activeKey] = v.tobeUpdate[v.activeKey];
      v.loading = true;
      const res = await proxy.$axios.put(
        `/api/v1/users/${v.tobeUpdate.id}/${v.activeKey}`,
        obj
      );
      v.loading = false;
      if (res.status == 200) {
        proxy.$msg.success("update success 🎉");
        v.shownameModal = false;
        v.data = v.tobeUpdate;
        store.commit("SET_USERINFO", v.tobeUpdate);
        localStorage.setItem("_userInfo", JSON.stringify(v.tobeUpdate));
        store.commit("SET_USERNAME", v.tobeUpdate.username);
        if (v.activeKey == "email") doLogout();
      }
    }

    async function changepwd(id) {
      v.loading = true;
      try {
        const res = await proxy.$axios.put(`/api/v1/users/${id}/password`, {
          oldPassword: v.oldpwd,
          password: v.newpwd1,
        });
        if (res.status == 200) {
          v.loading = false;
          proxy.$msg.success("change password success");
          v.finished = true;
          v.showpwdModal = false;
          // doLogout();
          countDown();
        }
      } catch (err) {
        v.loading = true;
        proxy.$msg.error("request failed");
      }
    }

    const countDown = () => {
      let secondsToGo = 5;
      const modal = Modal.success({
        title: "Password change completed",
        content: `Will jump to the login page in ${secondsToGo} second.`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `Will jump to the login page in ${secondsToGo} second.`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
        doLogout();
      }, secondsToGo * 1000);
    };

    function openModal(key) {
      v.tobeUpdate = JSON.parse(JSON.stringify(v.data));
      v.shownameModal = true;
      v.activeKey = key;
    }

    // 注销
    const doLogout = (e) => {
      localStorage.clear();
      store.commit("SET_LOGIN_STATUS", false);
      router.replace("/login");
    };

    return {
      ...toRefs(v),

      changepwd,
      updateUser,
      openModal,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/mixins";

.content {
  background-color: $color-white;
  padding: 15px;
}
.footer {
  text-align: center;
}
.table {
  margin-top: 15px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
